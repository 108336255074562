import api from './api'
import TmrBaseResource from './TmrBaseResource'

export default class AppDatas extends TmrBaseResource {
  static endpoint = '/appDatas'

  static getValue(code?: string) {
    return api
      .get(`${this.endpoint}`, { id: null }, { params: { code } })
      .then((res: any) => (res.data.length ? res.data[0].value : null))
  }
}
