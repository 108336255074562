import React, { Component } from 'react'
import {
  AntennaBottomControl,
  Box,
  Button,
  Card,
  CustomProductRow,
  InfoCard,
  Input,
  Page,
  Placeholder,
  Select,
  SelectItemModal,
  Spacer,
  Tab,
  TextBox,
  Icons,
} from 'components'
import AppStore from 'AppStore'
import { __, T } from 'translations/i18n'
import styled from '@emotion/styled'
import { askUserConfirmation, closeModal, openModal, showToast, sleep } from 'shared/utils'
import { CQTag, CQCounters, CQSerie, Causal, QualitySheet, ProductionOrderRow, CQLot } from 'api/types'
import RemoteConfig from 'shared/RemoteConfig'
import EncodingsQC from 'api/EncodingsQC'
import Sounds from 'shared/Sounds'
import SelectLotsModal from 'components/modals/SelectLotsModal'
import DataDogLogger from 'shared/DataDogLogger'
import QualitySheetModal from 'components/modals/QualitySheetModal'
import QualityControlAgent from './QualityControlAgentNew'
import AppDatas from '../../api/AppDatas'
import QualityProvider from './QualityProvider'

interface State {
  showQualitySheetModal: boolean
  options: { value: string; label: string; active: boolean }[]
  isReading: boolean
  isVerifing: boolean
  isWriting: boolean
  errorSerie: any
  serie?: CQSerie
  counters?: CQCounters
  item?: any
  tag?: CQTag
  warningText?: string
  isSerieSet: boolean
  isActionInProgress: boolean
  causal: Causal[]
  selectedCausal?: Causal
  qualitySheetData?: QualitySheet
  lastSearchedSerieCode?: string
  showScartoModal: boolean
  hideStartReaderButton: boolean
  lots?: CQLot[]
  currentProductQuantity?: number
  hasOperated: boolean
  isVAS: boolean
  hasUnexpectedTag?: boolean
  hasEmptyEncodableQuantity?: boolean
  lastReadedTag?: string
  quantity: number
  fastCode?: string
  serieHasTag?: boolean
}

export default class QualityControlWorker extends Component<{}, State> {
  state: State = {
    showQualitySheetModal: false,
    options: [],
    isReading: false,
    isVerifing: false,
    isWriting: false,
    causal: [],
    errorSerie: '',
    isSerieSet: false,
    isActionInProgress: false,
    showScartoModal: false,
    hideStartReaderButton: false,
    hasOperated: false,
    isVAS: false,
    hasUnexpectedTag: false,
    hasEmptyEncodableQuantity: false,
    quantity: 0,
  }
  tipoCQ = AppStore.defaultPlace?.attributes?.['tmr.ferragamo.integration.fast.series.tipocq']
  isWSM2 = AppStore.defaultPlace?.attributes?.['tmr.ferragamo.integration.fast.series.wsm2.worker'] === 'S'
  clearInputRef: any = undefined
  inputRef = React.createRef<HTMLInputElement>()
  operation = RemoteConfig.getOperationConfig<any>('quality-control-worker')
  isPelletteria = (AppStore.defaultPlace?.attributes as any)?.categoryType === 'P'
  async componentDidMount() {
    try {
      await this.stopReader()
      const causal = await EncodingsQC.fetchCausal(this.isPelletteria ? 'P' : 'C')
      this.setState({ causal })
    } catch (error) {
      DataDogLogger.addError(error, { type: 'fetchCausal' })
      showToast({
        sound: false,
        title: __(T.error.error),
        description: (error as Error).message,
        status: 'error',
      })
    }
  }

  async componentWillUnmount() {
    await this.stopReader()
  }

  toggleQualitySheetDetail = () => {
    const { showQualitySheetModal, serie } = this.state
    DataDogLogger.addAction('toggleQualitySheetDetail', {
      serie: serie ?? 'serie is undefined',
      show: !showQualitySheetModal,
    })
    this.setState({ showQualitySheetModal: !showQualitySheetModal })
  }

  changeOptions = async (selectedOption: string) => {
    const { options } = this.state
    options.map((option) => {
      option.active = option.value === selectedOption
      return option
    })
    this.setState({
      options,
      selectedCausal: undefined,
    })
  }

  clearStatus = async () => {
    const { isSerieSet, serieHasTag } = this.state
    await this.stopReader()
    this.inputRef = this.clearInputRef
    this.setState({
      showQualitySheetModal: false,
      options: isSerieSet ? (serieHasTag
        ? [{ value: 'COLLAUDO', label: 'collaudo', active: true },
          { value: 'CANCELLAZIONE', label: 'cancellazione', active: false }]
        : [{ value: 'COLLAUDO', label: 'collaudo', active: true }]) : [],
      isReading: false,
      isVerifing: false,
      isWriting: false,
      errorSerie: '',
      isSerieSet: false,
      isActionInProgress: false,
      qualitySheetData: undefined,
      serie: undefined,
      counters: undefined,
      item: undefined,
      tag: undefined,
      warningText: undefined,
      showScartoModal: false,
      isVAS: false,
      hasUnexpectedTag: false,
      hasEmptyEncodableQuantity: false,
      lastReadedTag: undefined,
    })
  }

  getUpdatedCounters = async (code?: string) => {
    const { serie } = this.state
    const serieCode = code ?? serie!.code
    const counters: CQCounters = await EncodingsQC.getEncodable(serieCode)
    if (!counters) throw Error('Impossibile definire contatori serie')
    return counters
  }
  //funzione chiamata quando si cerca una serie
  searchSerie = async (code: string) => {
    await this.getUpdatedSerie(code)
  }

  getUpdatedSerie = async (code: string, startReader = true) => {
    try {
      await this.stopReader()
      code = code.toUpperCase()
      const { serie } = this.state
      const { options } = this.state
      let warningText

      if (this.isPelletteria && code.length !== 10) throw new Error(__(T.error.serie_code_10_characters))
      if (!this.isPelletteria && code.length !== 14) throw new Error(__(T.error.serie_code_14_characters))

      let newSerie = await EncodingsQC.getCQCustomProductionOrder(code, this.operation)

      if (newSerie.worker.code && newSerie.worker.code !== AppStore.defaultPlace?.code)
        throw new Error(__(T.error.serie_wrong_place))
      if (newSerie.status === 'CLOSED') throw new Error(__(T.error.serie_inspector_check))

      if (!newSerie) {
        console.log('no serie')
        throw new Error(__(T.error.serie_not_found))
      }
      if (serie && newSerie?.code !== serie?.code && serie!.data!.currentLotData![0].items.length !== 0) {
        if (
          !(await askUserConfirmation(
            __(T.messages.serie_change_confirm),
            __(T.messages.serie_close_product_not_associated)
          ))
        ) {
          this.inputRef = this.clearInputRef
          return false
        }
        await this.closeLotto()
      }
      let row
      newSerie.rows.sort((a, b) => a.product.size.value - b.product.size.value)
      if (this.isPelletteria) row = newSerie.rows[0]
      else row = newSerie.rows.find((r) => r.rowCode === code) as any

      if (!row?.product) {
        console.log('no prodotto', row)
        throw Error('Taglia/calzata non trovata')
      }
      DataDogLogger.addAction('searchSerie', { serie: newSerie })
      if (newSerie.availableQuantity === 0)
        showToast({
          title: 'Attenzione',
          description: "Esistono pezzi battezzati sulla serie. Usa l'operazione di cancellazione per rimuoverli",
          status: 'warning',
        })

      let hideStartReaderButton = false
      let hasUnexpectedTag = false
      let fastCode = ''
      let serieHasTag = false
      if (newSerie && newSerie.destination && newSerie.destination.activeTag !== '') {
        warningText = newSerie.destination.fastMess
        fastCode = newSerie.destination.fastCode
        if (newSerie.destination!.activeTag === 'N') hideStartReaderButton = true
        if (warningText === 'NON DEVE CONTENERE IL TAG') hasUnexpectedTag = true
        serieHasTag = await QualityProvider.serieHasTag(fastCode)
        this.setState({ fastCode })
      }
      let isVAS = false
      if (newSerie && newSerie.customerOrder === '24S1') isVAS = true
      const qualitySheetData = (
        await EncodingsQC.getQualitySheet(
          newSerie.qualityCode || '',
          newSerie?.worker.code,
          row?.product?.style?.value ?? ''
        )
      )[0] // 'Q_CODE_TEST', 'studio-pelle'

      const counters = await this.getUpdatedCounters(newSerie.code)
      if (!counters) throw new Error(__('Errore nel recupero dei counters'))
      let hasEmptyEncodableQuantity = false
      if (counters.encodableCounts.quantity <= 0) hasEmptyEncodableQuantity = true
      let currentLotData = newSerie.lots?.filter((lot) => lot.status === 'INSPECTED_1')[0]
      const currentProductCounters =
        counters.encodableCounts.rows &&
        counters.encodableCounts.rows.filter(function (v) {
          return v.fit === row.product.fit.value && v.size === row.product.size.value
        })[0]
      if (currentProductCounters && currentProductCounters.quantity === 0) {
        showToast({
          title: 'Attenzione',
          description:
            'Limite pezzi raggiunto per taglia e calzata selezionate oppure controllare quantità avanzate su WSM2',
          status: 'warning',
        })
      }
      if (counters.encodableCounts.quantity === 0) {
        if (currentLotData && currentLotData.items!.length > 0 && this.state.hasOperated) {
          await EncodingsQC.changeStatusLottoToReadyInspection(currentLotData.id)
          newSerie = await EncodingsQC.getCQCustomProductionOrder(code, this.operation)
          currentLotData = newSerie.lots?.filter((lot) => lot.status === 'INSPECTED_1')[0]
          showToast({
            title: 'Attenzione',
            description: 'Lotto chiuso correttamente, impossibile battezzare altri tag.',
            status: 'warning',
          })
          this.clearStatus()
          return
        }
        showToast({
          title: 'Attenzione',
          description: 'Impossibile battezzare altri tag, controllare avanzamento in WSM2',
          status: 'warning',
        })
        startReader = false
      }
      const newSerieFormatted: CQSerie = {
        id: newSerie.id,
        code: newSerie.code,
        productId: row.product.id,
        data: {
          values: {
            'Tag/Cal': `${row.product.size.value}/${row.product.fit.value}`,
            Modello: row.product.style.value,
            Variante: row.product.variant.value,
            Stagione: row.product.season.slice(0, 2) + row.product.season.slice(4),
            Lavorante: newSerie.worker.description ?? 'aa',
            'Pezzi totali serie': counters.itemStatusCounts.seriesSize,
          },
          additional: {
            workerSyncCode: newSerie.worker.syncCode,
            productSku: row.product.sku,
            productSize: row.product.size.value,
            productCode: row.product.code,
            customerOrder: newSerie.customerOrder?.trim() ?? '',
            nationCode: newSerie.worker.countryCode ?? '',
          },
          descriptions: {
            Modello: row.product.style.valueDescription,
            Taglia: row.product.size.valueDescription,
            Calzata: row.product.fit.valueDescription,
            Variante: row.product.variant.valueDescription,
          },
          currentLotData: [currentLotData!],
        },
      }
      const lots = newSerie.lots?.filter((lot) => lot.status !== 'INSPECTED_1')
      this.inputRef = this.clearInputRef
      this.setState({
        serie: newSerieFormatted,
        errorSerie: undefined,
        warningText,
        isSerieSet: true,
        counters,
        qualitySheetData,
        lastSearchedSerieCode: code,
        hideStartReaderButton,
        lots,
        currentProductQuantity: currentProductCounters ? currentProductCounters.quantity : 0,
        isVAS,
        hasUnexpectedTag,
        hasEmptyEncodableQuantity,
        serieHasTag,
        options: serieHasTag
          ? [{ value: 'COLLAUDO', label: 'collaudo', active: true },
            { value: 'CANCELLAZIONE', label: 'cancellazione', active: false }]
          : [{ value: 'COLLAUDO', label: 'collaudo', active: true }],
      })
      Sounds.tap()
      const mode = options?.find((opt) => opt?.active === true)?.value
      //in scarto non faccio partire il reader automaticamente perchè va prima selezionata la causale di scarto
      if (
        mode === 'COLLAUDO' &&
        newSerieFormatted &&
        counters.encodableCounts.quantity !== 0 &&
        startReader
      ) {
        this.startReader()
      }
      return true
    } catch (error) {
      DataDogLogger.addError(error, { type: 'searchSerie' })
      showToast({
        title: __(T.error.error),
        description: (error as Error).message ?? __(T.error.serie_error),
        status: 'error',
      })
      this.clearStatus()
      return false
    }
  }

  startReader = async () => {
    try {
      this.setState({
        isReading: true,
      })
      if (!AppStore.emulation) {
        await QualityControlAgent.testConnection()
        const { options, selectedCausal } = this.state
        const mode = options.find((opt) => opt.active === true)?.value
        if (mode === 'SCARTO' && !selectedCausal) throw Error('Imposta una causale prima di procedere')
        const tag = await QualityControlAgent.readTag('fake', 'fake')
        if (tag.status === '4') return this.startReader() //non è stato letto niente, riparte la lettura
        if (tag.status === '99') return //Operazione interrotta che non mostra popup di error
        if (tag.status === '0' && tag.uid === '') throw Error('Errore di lettura') //Lettura andata a buon fine ma senza il tag
        if (tag.status !== '0') throw Error(tag.errorDesc) // Gestione di tutti gli altri tipi di errore
        this.setState({ tag })
        this.verifyReadedTag(tag)
      }
    } catch (error) {
      this.setState({ isReading: false })
      showToast({
        title: __(T.error.error),
        description: (error as Error).message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  verifyReadedTag = async (tag: CQTag) => {
    if (this.state.lastReadedTag && this.state.lastReadedTag === tag.uid) return this.startReader()
    try {
      this.setState({
        isReading: false,
        isVerifing: true,
      })
      const { options, serie, currentProductQuantity } = this.state
      const mode = options?.find((opt) => opt?.active === true)?.value
      if (mode === 'COLLAUDO' && currentProductQuantity === 0 && !this.isPelletteria)
        throw Error(
          'Limite pezzi raggiunto per taglia e calzata selezionate oppure controllare quantità avanzate su WSM2'
        )
      const res = await EncodingsQC.verifyCQWorker({
        productionOrderId: serie!.id,
        productId: serie!.productId,
        identifiers: [{ code: tag?.uid, type: 'NFCTag' }] as any,
        configurationId: this.operation.id,
        operationType: mode,
      })
      if (res.errors.length > 0) {
        for (let i = 1; i <= res.errors.length; i++) {
          if (res.errors[i] && res.errors[i].errorCode) {
            //If there is more than one error, show them all in modals, than throw the first of them as effective error
            showToast({
              title: __(T.error.error),
              description: T.error[res.errors[i].errorCode.replaceAll('.', '_')]
                ? __(T.error[res.errors[i].errorCode.replaceAll('.', '_')])
                : res.errors[i].errorCode.replaceAll('.', '_'),
              status: 'error',
            })
          }
        }
        if (res.errors[0].errorCode) {
          throw Error(
            T.error[res.errors[0].errorCode.replaceAll('.', '_')]
              ? __(T.error[res.errors[0].errorCode.replaceAll('.', '_')])
              : res.errors[0].errorCode.replaceAll('.', '_')
          )
        }
      }
      if (res.warnings.length > 0 && !(await askUserConfirmation('Messaggio di warning nella serie', 'Proseguire?'))) {
        return this.setState({ isVerifing: false })
      }
      this.setState({
        item: res.item,
        tag,
      })
      if (mode === 'SCARTO') return this.setState({ showScartoModal: true })
      this.setState({ isVerifing: false })
      if (
        mode === 'COLLAUDO' &&
        (!res.item ||
          res.item.status !== 'SC' ||
          (res.item.status === 'SC' &&
            (await askUserConfirmation(
              'Warning',
              `Pezzo scartato con la seguente causale: "${res.item?.causal?.description ?? ''}". Continuare?`
            ))))
      )
        this.collaudoItem()
      if (mode === 'CANCELLAZIONE') {
        if (!res.item) throw Error('Impossibile eseguire cancellazione, nessun item associato')
        this.cancellaItem()
      }
    } catch (error) {
      this.setState({
        isReading: false,
        isVerifing: false,
      })
      showToast({
        title: __(T.error.error),
        description: (error as Error).message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  collaudoItem = async () => {
    const { tag, serie, counters, lastSearchedSerieCode } = this.state
    const additionalSerieData = serie?.data.additional
    try {
      DataDogLogger.addAction('processAssociate', { serie, tag })
      this.setState({ isWriting: true })
      if (
        counters!.itemStatusCounts.seriesSize - counters!.itemStatusCounts.tested <= 0 ||
        counters!.encodableCounts.quantity <= 0
      )
        throw Error(__(T.error.serie_limit_associate_error))
      if (!AppStore.emulation) {
        const cliente = additionalSerieData?.customerOrder?.trim() || 'N00035'
        //if the string is less than 6 characters it will add 0 at the end of the string
        const clienteFinal = cliente.length < 6 ? cliente.padEnd(6, '0') : cliente
        const res = await QualityControlAgent.writeTagCQ(
          AppStore.loggedUser?.username as string,
          AppStore.defaultPostazione ?? '',
          tag?.uid as string,
          additionalSerieData?.workerSyncCode as string,
          serie?.code as string,
          (serie?.data.values.Stagione as string) ?? '-',
          (additionalSerieData?.productSku as string) ?? '',
          additionalSerieData?.productSize ?? '',
          clienteFinal,
          additionalSerieData?.nationCode ?? ''
        )
        if (res?.status !== '0') throw Error(__(T.error.writing_tag_error))
      }
      const response = await EncodingsQC.associateLot({
        productionOrderId: serie?.id,
        productId: serie!.productId,
        identifiers: [{ code: tag?.uid, type: 'NFCTag' }] as any,
        zoneId: AppStore.defaultZone?.id,
        configurationId: this.operation.id,
        scannedBarCode: lastSearchedSerieCode,
      })
      if (!response.success) {
        console.log(response.error)
        const errorFormatted =
          response.errors && T.error[response.errors[0].errorCode.replaceAll('.', '_')]
            ? __(T.error[response.errors[0].errorCode.replaceAll('.', '_')])
            : __(T.error.product_association_error)
        throw Error(errorFormatted)
      }
      this.setState({
        isWriting: false,
        isReading: false,
        hasOperated: true,
        lastReadedTag: tag?.uid,
      })
      Sounds.success()
      showToast({
        title: __(T.misc.success),
        description: __(T.messages.product_associated_correctly),
        status: 'success',
      })
      await this.getUpdatedSerie(lastSearchedSerieCode ?? '', this.isPelletteria)
    } catch (error) {
      DataDogLogger.addError(error, { type: 'processAssociate' })
      this.setState({ isWriting: false })
      showToast({
        title: __(T.error.error),
        description: (error as Error).message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  scartoItem = async (reversible = true) => {
    const { tag, serie, selectedCausal, lastSearchedSerieCode } = this.state
    try {
      DataDogLogger.addAction('processDrop', { serie, tag, selectedCausal })
      this.setState({ showScartoModal: false, isReading: false, isWriting: true })
      if (!AppStore.emulation) {
        const res = await QualityControlAgent.writeTagSC(
          AppStore.loggedUser?.username as string,
          tag?.uid as string,
          AppStore.defaultPostazione ?? '',
          selectedCausal?.id as string
        )
        if (res?.status !== '0') throw Error(__(T.error.writing_tag_error))
      }
      const response = await EncodingsQC.dropLotWorker({
        productionOrderId: serie!.id,
        productId: serie!.productId,
        identifiers: [{ code: tag?.uid, type: 'NFCTag' }] as any,
        zoneId: AppStore.defaultZone?.id,
        configurationId: this.operation.id,
        causalId: selectedCausal?.id,
        reversible,
      })
      if (!response.success) throw Error(__(T.error.product_drop_error))
      Sounds.success()
      showToast({
        title: __(T.misc.success),
        description: __('Prodotto scartato correttamente'),
        status: 'success',
      })
      this.setState({
        errorSerie: undefined,
        isWriting: false,
        isReading: false,
      })
      this.changeOptions('COLLAUDO')
      await this.getUpdatedSerie(lastSearchedSerieCode ?? '', false)
    } catch (error) {
      DataDogLogger.addError(error, { type: 'processDrop' })
      this.setState({ isWriting: false })
      showToast({
        title: __(T.error.error),
        description: (error as Error).message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  cancellaItem = async () => {
    const { tag, serie, item, lastSearchedSerieCode } = this.state
    try {
      DataDogLogger.addAction('processDelete', { tag, serie, item })
      this.setState({ isWriting: true })
      if (!AppStore.emulation) {
        const res = await QualityControlAgent.cancelTag(
          AppStore.loggedUser?.username as string,
          tag?.uid as string,
          AppStore.defaultPostazione ?? ''
        )
        if (res?.status !== '0') {
          throw Error(__(T.error.tag_reset_error))
        }
      }
      await EncodingsQC.deleteLotWorker({
        productionOrderId: item?.productionOrderRow?.order?.id ?? serie?.id,
        productId: item?.product?.id as string,
        identifiers: [{ code: tag?.uid, type: 'NFCTag' }] as any,
        zoneId: AppStore.defaultZone?.id,
        configurationId: this.operation.id,
      })
      this.setState({
        errorSerie: undefined,
        isWriting: false,
        isReading: false,
      })
      Sounds.success()
      showToast({
        title: __(T.misc.success),
        description: __('Prodotto cancellato correttamente'),
        status: 'success',
      })
      this.changeOptions('COLLAUDO')
      await this.getUpdatedSerie(lastSearchedSerieCode ?? '', false)
    } catch (error) {
      DataDogLogger.addError(error, { type: 'processDelete' })
      this.setState({ isWriting: false })
      showToast({
        title: __(T.error.error),
        description: (error as Error).message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  stopReader = async () => {
    await QualityControlAgent.stopReader()
    await sleep(700)
    this.setState({
      isReading: false,
    })
  }

  //funzione che chiude il lotto
  closeLotto = async () => {
    try {
      const { serieHasTag, serie, lastSearchedSerieCode, quantity } = this.state
      DataDogLogger.addAction('closeSerie', { serie: serie })
      this.setState({ isActionInProgress: true }) //settings action in progress for disable buttons click
      if (this.tipoCQ === 'CAMP_LOTTI' && this.isWSM2 && !serieHasTag) {
        if (quantity > 0) {
          await EncodingsQC.confirmWorkerLottiNoTag(serie!.data!.currentLotData![0].code, serie!.productId, quantity)
        }
        else {
          throw Error('Nessun pezzo battezzato, impossibile avanzare il lotto')
        }
      }
      else {
        if (serie!.data!.currentLotData![0].items.length === 0)
          throw Error('Associare almeno un item prima di chiudere il lotto')
        const res = await EncodingsQC.changeStatusLottoToReadyInspection(serie!.data!.currentLotData![0].id)
        if (res && res.errors.length > 0) {
          throw Error(T.error[res.errors[0].errorCode] ? __(T.error[res.errors[0].errorCode]) : res.errors[0].errorCode)
        }
      }
      this.clearStatus()
      showToast({
        title: __(T.misc.success),
        description: `Lotto chiuso correttamente `,
        status: 'success',
      })
      this.setState({ isActionInProgress: false, quantity: 0 })
      return this.searchSerie(lastSearchedSerieCode ?? '')
    } catch (error) {
      DataDogLogger.addError(error, { type: 'closeSerie' })
      showToast({
        sound: false,
        title: __(T.error.error),
        description: (error as Error).message,
        status: 'error',
      })
      return this.setState({ isActionInProgress: false })
    }
  }

  //Mostra modale per info lotti
  showLotsModal = async () => {
    const { lots, serie, counters } = this.state
    const lotCounts = counters?.itemStatusCounts.lotCounts
    const serieCode = serie?.code
    const modalId = 'cq-lots'
    let options: any[] = []
    for (let i = 0; i < lots!.length; i++) {
      const currentLotCount = { tested: lotCounts?.find((itmInner) => itmInner.lotId === lots![i].id)?.tested }
      options.push({
        ...lots![i],
        ...currentLotCount,
      })
    }
    options = options.sort((a, b) => (a.code > b.code ? 1 : -1))
    return new Promise((resolve) => {
      openModal({
        id: modalId,
        body: (
          <SelectLotsModal
            modalId={modalId}
            title="LOTTI CONFERMATI"
            visible
            options={options ?? []}
            onSelect={(items) => {
              resolve(items)
              closeModal(modalId)
            }}
            lotCode="code"
            date="creationDate"
            serie={serieCode ?? ''}
            onClose={() => {
              closeModal(modalId)
            }}
          />
        ),
      })
    })
  }

  renderHeader = () => {
    const { options, isReading, isVerifing, isWriting, qualitySheetData } = this.state
    const showAntennaReadingIcon = isReading || isWriting || isVerifing
    return (
      <>
        {qualitySheetData && (
          <Button variant="secondary" style={{ minHeight: 55 }} onClick={this.toggleQualitySheetDetail}>
            Scheda qualità
          </Button>
        )}
        <Spacer />
        <Tab options={options} onOptionSelected={this.changeOptions} disabled={showAntennaReadingIcon} />
      </>
    )
  }

  renderBottomBar = () => {
    const { isReading, isVerifing, isWriting, hideStartReaderButton, options, hasEmptyEncodableQuantity } = this.state
    const showAntennaReadingIcon = isReading || isWriting || isVerifing
    const mode = options?.find((opt) => opt?.active === true)?.value
    const disableButton = mode === 'COLLAUDO' && hasEmptyEncodableQuantity
    return (
      <>
        {showAntennaReadingIcon && (
          <Box row>
            <Box flex>
              <AntennaBottomControl />
            </Box>
            <Spacer />
            <Button title="Stop lettore" onClick={this.stopReader} disabled={isWriting || isVerifing} />
          </Box>
        )}
        {!showAntennaReadingIcon && !hideStartReaderButton && (
          <Button title={__(T.placeholder.start_reader)} onClick={this.startReader} disabled={disableButton} />
        )}
      </>
    )
  }

  renderScartoModal = () => {
    return (
      <Box>
        <TextBox text="Seleziona un tipo di scarto" type="error" />
        <Box row mt={15} center>
          <Button
            variant="default"
            title={__(T.misc.cancel)}
            onClick={() => {
              return this.setState({ showScartoModal: false })
            }}
          />
          <Spacer />
          <Button title="Reversibile" onClick={this.scartoItem} />
          <Spacer />
          <Button title="Irreversibile" onClick={() => this.scartoItem(false)} />
        </Box>
      </Box>
    )
  }

  renderInfoSerie = () => {
    const {
      serie,
    } = this.state
    if (!serie) return
    return (
      <>
        <InfoCard item={serie!.data.values} title="Serie" subtitle={serie!.code} />
        <Spacer />
        <CounterContainer row={false}>
          <Card title="Lotto" nomargin style={{ minWidth: 125, padding: 5, paddingLeft: 10 }}>
            <Box style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <Counter>
                {(serie.data.currentLotData![0] && serie.data.currentLotData![0].code) ?? 'Nessun lotto aperto'}
              </Counter>
              <Icons.Info
                style={{ alignSelf: 'center', marginTop: -20 }}
                width={25}
                height={25}
                onClick={() => {
                  this.showLotsModal()
                }}
              />
            </Box>
          </Card>
        </CounterContainer>
        <Spacer />
      </>
    )
  }
  renderSideBar = () => {
    const { errorSerie, isReading, isWriting, isVerifing, warningText, options, isVAS, isSerieSet } = this.state
    const isDisabled = isReading || isWriting || isVerifing
    const selectedOption = options.find((opt) => opt.active === true)?.value
    return (
      <>
        <Page.Sidebar style={{ overflowY: 'auto', scrollbarWidth: 'none' }}>
          <Input
            registerClear={(cb) => (this.clearInputRef = cb)}
            inputRef={this.inputRef}
            autoFocus={this.inputRef?.current?.value === '' && selectedOption !== 'SCARTO' && !isSerieSet}
            onChange={() => this.forceUpdate()}
            barcode
            error={errorSerie}
            placeholder="Barcode serie"
            onEnter={this.searchSerie}
            disabled={isDisabled}
            focusOptions={{ preventScroll: true }}
          />
          <Spacer />
          {warningText && (
            <>
              <WarningText text={warningText} type="warning" p={2} />
              <Spacer />
            </>
          )}
          {isVAS && (
            <>
              <WarningText text="ATTENZIONE SERIE VAS" type="warning" p={2} />
              <Spacer />
            </>
          )}
          {this.renderInfoSerie()}
        </Page.Sidebar>
      </>
    )
  }

  insertQuantity = (quantity: number) => {
    const { isSerieSet, counters } = this.state
    const encodable = counters!.encodableCounts.quantity
    if (!isSerieSet) return
    if (quantity <= 0) {
      showToast({
        title: __(T.error.error),
        description: 'Quantità inserita non valida',
        status: 'error',
      })
      return
    }
    if (quantity > encodable) {
      showToast({
        title: __(T.error.error),
        description: 'Quantità inserita maggiore di quella ispezionabile',
        status: 'error',
      })
      return
    }
    this.setState({ quantity })
  }

  renderIsReadingBox = () => {
    return (
      <Box flex center>
        <Placeholder style={{ width: 370 }}>{__(T.messages.near_product_to_reader)}</Placeholder>
      </Box>
    )
  }

  renderIsVerifingBox = () => {
    return (
      <TagReadBox bgColor="#18a0c2">
        <TagReadPlaceholder color="#fff" style={{ width: 370 }}>
          Verifica in corso... Non muoverlo dal lettore!
        </TagReadPlaceholder>
      </TagReadBox>
    )
  }

  renderIsWritingBox = () => {
    return (
      <TagReadBox bgColor="#18a0c2">
        <TagReadPlaceholder color="#fff" style={{ width: 370 }}>
          Scrittura in corso... Non muoverlo dal lettore!
        </TagReadPlaceholder>
      </TagReadBox>
    )
  }

  renderScartoSelect = () => {
    const { selectedCausal, causal, serie } = this.state
    return (
      <Box flex style={{ width: '100%' }}>
        <Select
          onSelect={(causalSel) => {
            DataDogLogger.addAction('Causal selection', { serie, causal })
            this.setState({ selectedCausal: causalSel })
          }}
          placeholder={__(T.placeholder.drop_causal)}
          options={causal}
          config={{ value: 'id', label: 'fastId', secondaryLabel: 'description' }}
          isClearable={false}
          defaultValue={selectedCausal}
          multiLabel
        />
      </Box>
    )
  }

  renderQualitySheetModal = () => {
    const { showQualitySheetModal, serie, qualitySheetData } = this.state
    return (
      <>
        {showQualitySheetModal && (
          <QualitySheetModal
            serie={serie}
            qualitySheet={qualitySheetData}
            onClose={this.toggleQualitySheetDetail}
            visible={this.state.showQualitySheetModal}
          />
        )}
      </>
    )
  }

  emulateTag = (tags: string[]) => {
    const tagRead = {
      errorDesc: '',
      status: '0',
      uid: tags[0],
      uuid: '045442D2B16C80045442D2B16C80',
    }
    this.verifyReadedTag(tagRead)
  }

  renderInsertQuantity = () => {
    const { quantity, counters } = this.state
    const encodable = counters!.encodableCounts.quantity
    if (quantity === 0) {
      return (
        <Input
          onChange={() => this.forceUpdate()}
          barcode
          placeholder={"Inserisci quantità da avanzare (max. " + encodable + ")"}
          onEnter={(q) => this.insertQuantity(Number(q))}
        />
      )
    }
    else {
      return (
        <CounterContainer row>
          <Card title={__(T.misc.quantity_to_advance)} nomargin style={{ flexGrow: 1, padding: 5, paddingLeft: 10 }}>
            <Counter>{quantity}</Counter>
          </Card>
          <Spacer />
          <Button title="Reset" onClick={() => this.setState({ quantity: 0 })} variant='secondary' style={{ marginLeft: 'auto' }} />
        </CounterContainer>
      )
    }
  }

  render() {
    const {
      isReading,
      isVerifing,
      isWriting,
      isSerieSet,
      options,
      showScartoModal,
      counters,
      hasUnexpectedTag,
      isActionInProgress,
      serieHasTag,

    } = this.state
    const disableButton = isWriting || isVerifing || hasUnexpectedTag
    const mode = options.find((opt) => opt.active === true)?.value
    const lotCounts = counters?.itemStatusCounts.lotCounts.filter((lot) => lot.lotStatus === 'INSPECTED_1')[0]
    const encodable = counters?.encodableCounts.quantity ?? 0
    const tested = lotCounts?.tested ?? 0
    const discarded = lotCounts?.discarded ?? 0
    const toBeTested = lotCounts?.toBeTested ?? 0
    return (
      <Page
        title="Quality Control"
        enableEmulation
        headerRight={this.renderHeader()}
        emulationFunction={this.emulateTag}
      >
        {this.renderSideBar()}
        <Page.Content>
          {isSerieSet && (
            <>
              <CounterContainer row>
                <CounterContainer flex>
                  <CounterContainer flex>
                    <Card title="Totale pezzi collaudabili" nomargin style={{ padding: 5, paddingLeft: 10 }}>
                      <Counter>{encodable === 0 ? '-' : encodable}</Counter>
                    </Card>
                  </CounterContainer>
                  <Spacer />
                  <CounterContainer row flex>
                    <Card title={__(T.placeholder.tested)} nomargin style={{ minWidth: 120, padding: 5, paddingLeft: 10 }} disabled={!serieHasTag} >
                      <Counter>{tested === 0 ? '-' : tested}</Counter>
                    </Card>
                    <Spacer />
                    <Card title={__(T.placeholder.dropped)} nomargin style={{ minWidth: 120, padding: 5, paddingLeft: 10 }} disabled={!serieHasTag} >
                      <Counter>{discarded === 0 ? '-' : discarded}</Counter>
                    </Card>
                    <Spacer />
                    <Card title="Da ispezionare" nomargin style={{ padding: 5, paddingLeft: 10 }} disabled={!serieHasTag} >
                      <Counter>{toBeTested === 0 ? '-' : toBeTested}</Counter>
                    </Card>
                  </CounterContainer>
                </CounterContainer>
                <Spacer />
                <CounterContainer flex>
                  <Button style={{flex: 'auto'}}
                    title="Chiudi lotto"
                    disabled={disableButton}
                    loading={isActionInProgress}
                    variant="primary"
                    onClick={this.closeLotto}
                  />
                </CounterContainer>
              </CounterContainer>
              <Spacer />
              {!serieHasTag && (
                <>
                  {this.renderInsertQuantity()}
                  <Spacer />
                </>
              )}
            </>
          )}
          {serieHasTag && isSerieSet && !showScartoModal && (
            <>
              {mode === 'SCARTO' && this.renderScartoSelect()}
              <Box flex hcenter vcenter>
                {isReading && this.renderIsReadingBox()}
                {isVerifing && this.renderIsVerifingBox()}
                {isWriting && this.renderIsWritingBox()}
              </Box>
              <Spacer />
              {this.renderBottomBar()}
            </>
          )}
          {showScartoModal && this.renderScartoModal()}
        </Page.Content>
        {this.renderQualitySheetModal()}
      </Page>
    )
  }
}

const TagReadBox = styled.div<{ bgColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  ${({ bgColor }) => `background-color: ${bgColor}`};
  margin: 20px;
  border-radius: 20px;
`

const TagReadPlaceholder = styled.div<{ color: string }>`
  font-style: normal;
  font-weight: 700;
  font-size: 38px;
  line-height: 38px;
  text-align: center;
  cursor: default;
  ${({ color }) => `color: ${color}`};
`

const Counter = styled(Box)`
  min-width: 80px;
  font-size: 20px;
  font-weight: bold;
`

const CounterContainer = styled(Box)`
  justify-content: space-between;
`
const WarningText = styled(TextBox)`
  font-size: 15px;
`
