import React, { useState } from 'react'
import {
  Page,
  LeftHeader,
  KeyValueRow,
  RightContainer,
  Spacer,
  Box,
  UserInfoModal,
  SettingsModal,
  Image,
  Icons,
} from 'components'
import styled from '@emotion/styled'
import { navigate, refresh } from 'shared/router'
import { Routes } from 'pages'
import AppStore from 'AppStore'
import { T, __ } from 'translations/i18n'
import { TmrPlace, TmrWorkstation } from 'api/types'
import { hasCapability, showToast } from 'shared/utils'
import RemoteConfig, { EncodingConfig, InboundConfig, OutboundConfig } from 'shared/RemoteConfig'
import Zones from 'api/Zones'

export default function Dashboard() {
  const [userModalVisible, setuserModalVisible] = useState(false)
  const [settingsModalVisible, setsettingsModalVisible] = useState(false)
  const selectDefaultWorkstation = async (workstation: TmrWorkstation) => {
    await AppStore.saveDefaultWorkstation(workstation)
  }

  let isWSM2: boolean = false;

  if (AppStore?.defaultPlace?.attributes && AppStore?.defaultPlace?.attributes?.['tmr.ferragamo.integration.fast.series.tipocq'] === 'CAMP_LOTTI' || AppStore?.loggedUser?.place.attributes?.['tmr.ferragamo.integration.fast.series.tipocq'] === 'CAMP_LOTTI')
  {
    isWSM2 = true;
  }
  else if (AppStore?.defaultPlace?.attributes && AppStore?.defaultPlace?.attributes?.['tmr.ferragamo.integration.fast.series.tipocq'] === 'CENTO' || AppStore?.loggedUser?.place.attributes?.['tmr.ferragamo.integration.fast.series.tipocq'] === 'CENTO')
  {
    isWSM2 = AppStore?.defaultPlace?.attributes?.['tmr.ferragamo.integration.fast.series.wsm2.worker'] === 'S';
  }

  const workerAttribute = AppStore?.defaultPlace?.attributes ? AppStore?.defaultPlace?.attributes?.['tmr.ferragamo.integration.fast.series.tipocq'] : AppStore?.loggedUser?.place.attributes?.['tmr.ferragamo.integration.fast.series.tipocq'];

  const cqTypeValue = workerAttribute === 'CAMP_LOTTI' ? 'CQ Lotti'
: workerAttribute === 'CENTO' ? '100%' : null;

  const selectDefaultPlace = async (place: TmrPlace) => {
    const zone = await Zones.search({ 'place.id': place.id, zoneType: 'STOCK' })[0]
    await AppStore.saveDefaultZone(zone)
    await AppStore.saveDefaultPlace(place)
    refresh()
  }

  const routeMap: {
    'quality-control': Routes
    'quality-control-worker': Routes
    'quality-control-inspector': Routes
    'quality-control-info-serie': Routes
    'quality-control-info-lotto': Routes
    inbound: Routes
    outbound: Routes
    encoding: Routes
    inbound_nochecklist: Routes
    outbound_nochecklist: Routes
  } = {
    'quality-control': `/quality-control/:configCode`,
    'quality-control-worker': `/quality-control/worker/:configCode`,
    'quality-control-inspector': `/quality-control/inspector/:configCode`,
    'quality-control-info-serie': `/quality-control-info-serie`,
    'quality-control-info-lotto': `/quality-control-info-lotto`,
    inbound: `/inbound/:configCode`,
    outbound: `/outbound/:configCode`,
    encoding: `/encoding/:configCode`,
    inbound_nochecklist: `/inbound/:configCode/create`,
    outbound_nochecklist: `/outbound/:configCode/create`,
  }

  const iconMap = {
    inbound: <Icons.Inbound />,
    outbound: <Icons.Outbound />,
    transfers: <Icons.Transfers />,
  }

  const remoteOp = RemoteConfig.getAllShippingOperations().map((op) => {
    let operation: string = op.operationType
    const operationRoute = routeMap[operation]
    if (op.hasChecklist === 'no' && 'inboundMode' in op && (op as InboundConfig).inboundMode === 'parcelByParcel') {
      operation = 'inbound_nochecklist'
    }
    if (op.hasChecklist === 'no' && 'outboundMode' in op && (op as OutboundConfig).outboundMode === 'parcelByParcel') {
      operation = 'outbound_nochecklist'
    }
    return {
      operation: operationRoute,
      routeParams: { configCode: op.code },
      icon: iconMap[op.icon ?? op.operationType],
      text: op.description,
    }
  })

  const filterByWsm2 = (options): EncodingConfig[] => {
    return options.filter((op) => {
      return (
        (isWSM2 && op.code !== 'quality-control') ||
        (!isWSM2 && op.code !== 'quality-control-worker' && op.code !== 'quality-control-inspector')
      )
    })
  }

  let encodingOpRaw = RemoteConfig.getAllEncodingOperations()

  // encodingOpRaw = filterByWsm2(encodingOpRaw)
  
  const encodingOp = encodingOpRaw.map((op) => {
    let operationRoute = routeMap[op.operationType]
    let operationDescription = op.description
    let routeParamsCode = op.code
    if (op.code === 'quality-control') operationRoute = routeMap['quality-control']
    if (op.code === 'quality-control-worker') operationRoute = routeMap['quality-control-worker']
    if ((op.code === 'quality-control-worker') && (AppStore?.defaultPlace?.attributes && AppStore?.defaultPlace?.attributes?.['tmr.ferragamo.integration.fast.series.tipocq'] === 'CAMP_LOTTI'))
    {operationRoute = routeMap['quality-control-worker']
      routeParamsCode = 'quality-control-worker-lot'}
    if (op.code === 'quality-control-inspector') operationRoute = routeMap['quality-control-inspector']
    if ((op.code === 'quality-control-inspector') && (AppStore?.defaultPlace?.attributes && AppStore?.defaultPlace?.attributes?.['tmr.ferragamo.integration.fast.series.tipocq'] === 'CENTO'))
    {operationRoute = routeMap['quality-control-inspector']
      operationDescription = 'Quality Control 100'
      routeParamsCode = 'quality-control-100'}
    if ((op.code === 'quality-control-inspector') && (AppStore?.defaultPlace?.attributes && AppStore?.defaultPlace?.attributes?.['tmr.ferragamo.integration.fast.series.tipocq'] === 'CAMP_LOTTI'))
    {operationRoute = routeMap['quality-control-inspector']
      routeParamsCode = 'quality-control-inspector-lot'}
    return {
      operation: operationRoute,
      routeParams: { configCode: routeParamsCode },
      icon: <Icons.Encoding />,
      text: operationDescription,
    }
  })

  const transferToZoneOp: any[] = []

  if (
    AppStore.loggedUser?.roles.some((role) => role.capabilities.includes('Operation.TransferToZone.perform')) ||
    AppStore.loggedUser?.admin
  ) {
    transferToZoneOp.push({
      operation: '/transfer-to-zone',
      icon: <Icons.TransferToZone />,
      text: __(T.titles.transfer_to_zone),
    })
  }

  const operations: DashboardButtonProps[] = [
    // { operation: '/encoding', icon: <Icons.Encoding />, text: __(T.misc.encoding) },
    ...encodingOp,
    ...transferToZoneOp,
    ...remoteOp,
  ]

  hasCapability('Operation.TagInfoCQ.perform') &&
    operations.splice(1, 0, {
      operation: '/tag-info-quality-control',
      icon: <Icons.TagInfo />,
      text: __(T.misc.tag_info_cq),
    })

  hasCapability('Operation.TagInfoCQ.perform') && !isWSM2 &&
    operations.splice(1, 0, {
      operation: '/quality-control-info-serie',
      icon: <Icons.TagInfo />,
      text: __(T.misc.serie_info_cq),
    })

  hasCapability('Operation.TagInfoCQ.perform') && isWSM2 &&
    operations.splice(1, 0, {
      operation: '/quality-control-info-lotto',
      icon: <Icons.TagInfo />,
      text: 'Info Serie/Lotto',
    })

  hasCapability('Operation.TagInfo.perform') &&
    operations.splice(1, 0, { operation: '/tag-info', icon: <Icons.TagInfo />, text: __(T.misc.tag_info) })

  const showWorkstation = AppStore.loggedUser?.roles.find(
    (role) => role.code === 'encoder' || role.code === 'sample-shipping'
  )
  return (
    <Page title="Dashboard" header={null}>
      <Box flex row>
        <LeftHeader mv={showWorkstation && AppStore.isInspector ? 20 : 50}>
          <KeyValueRow
            onClick={() => setuserModalVisible(!userModalVisible)}
            label={__(T.misc.user)}
            value={AppStore.loggedUser?.username}
            image={<Icons.Info />}
          />
          <Spacer />
          {showWorkstation && (
            <KeyValueRow
              label={__(T.misc.workstation)}
              value={AppStore.defaultWorkstation?.code ?? '---'}
              optionModal={{
                onSelect: (item) => selectDefaultWorkstation(item[0]),
                field: 'code',
                options: AppStore.workstations ?? [],
                selected: [AppStore.defaultWorkstation],
                title: __(T.titles.select_workstation),
                searchable: true,
              }}
            />
          )}
          <Spacer />
          {AppStore.isInspector && (
            <KeyValueRow
              label={__(T.misc.place)}
              value={AppStore.defaultPlace?.code ?? '---'}
              optionModal={{
                onSelect: (item) => selectDefaultPlace(item[0]),
                field: 'code',
                options: AppStore.loggedUser?.rolesPlaces ?? [],
                selected: [AppStore.defaultPlace],
                title: __(T.titles.select_workstation),
                searchable: true,
              }}
            />
          )}
          <Spacer />
          {AppStore.isInspector && (
            <KeyValueRow label={__(T.misc.cqType)} value={cqTypeValue} />
          )}
        </LeftHeader>

        <RightContainer style={{ flex: 1.5, alignItems: 'center' }}>
          <DashView>
            {operations.map((operation, index) => (
              <DashboardButton key={index} {...operation} />
            ))}

            {operations.length % 2 === 1 && <DashboardFakeButton />}
          </DashView>
        </RightContainer>
      </Box>
      <UserInfoModal user={AppStore.loggedUser} onClose={() => setuserModalVisible(false)} visible={userModalVisible} />
      <SettingsModal onClose={() => setsettingsModalVisible(false)} visible={settingsModalVisible} />
    </Page>
  )
}

type DashboardButtonProps = {
  operation?: Routes
  routeParams?: any
  icon?: any
  text?: string
}

function DashboardButton({ operation, routeParams, icon, text }: DashboardButtonProps) {
  const onClick = () => {
    if (
      !AppStore.defaultWorkstation &&
      !AppStore.emulation &&
      routeParams?.configCode !== 'quality-control-worker' &&
      routeParams?.configCode !== 'quality-control-inspector' &&
      routeParams?.configCode !== 'quality-control' &&
      operation !== '/tag-info-quality-control' &&
      operation !== '/quality-control-info-serie' &&
      operation !== '/quality-control-info-lotto'
    ) {
      showToast({
        title: __(T.error.error),
        description: __(T.messages.no_workstation_selected),
        status: 'error',
      })
      return
    }
    if (routeParams?.configCode === 'quality-control') {
      if (!AppStore.defaultPlace) {
        showToast({
          title: __(T.error.error),
          description: __(T.messages.no_place_selected),
          status: 'error',
        })
        return
      }
      if (!AppStore.defaultPostazione || AppStore.defaultPostazione === '') {
        showToast({
          title: __(T.error.error),
          description: __(T.messages.no_seat_selected),
          status: 'error',
        })
        return
      }
    }
    operation && navigate(operation, routeParams)
  }
  return (
    <DashboardItemStyle onClick={onClick}>
      {icon && typeof icon !== 'string' && icon}
      {icon && typeof icon === 'string' && <DashImage alt={text} src={icon} />}
      {text}
    </DashboardItemStyle>
  )
}

const DashboardItemStyle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  text-align: center;

  width: 276px;
  height: 202px;
  background: #ffffff;
  border: 2px solid #dcdcdc;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 5px;
  margin: 30px 20px;
  margin-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
`

const DashboardFakeButton = styled.div`
  width: 276px;
  height: 202px;
  background: #ffffff;
  border: 2px dashed #eeeeee;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 5px;
  margin: 30px 20px;
  margin-top: 0px;
`

const DashImage = styled(Image)`
  margin-bottom: 10px;
  max-height: 100px;
  max-width: 100px;
`

const DashView = styled(Box)`
  max-width: 700px;
  justify-content: center;
  flex: 1;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-content: start;
  padding-top: 50px;
  flex-basis: 150px;
  flex-shrink: 0;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
`
